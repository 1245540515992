@import 'swiper.css';
@import 'header.css';
@import 'lightbox.css';
@import 'multi-range-slider.css';
@import 'datepicker.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    overflow-x: hidden;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: rgb(66 66 66);
    font-size: 16px;
    line-height: 22px;

}

.text-herotitle {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -.02em
}

h1 {
    font-size: 28px;
    line-height: 1.2;
    font-weight: 600;
}

h2 {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: .75rem;
}

h3 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: .75rem;
}

h3.plate-top {
    margin: 0;
}

h4 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: .5rem;
}

h5 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: .5rem;
}

h6 {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: .5rem;
}

img {
    height: 100%;
}

/* Video size rendering */
.media {
    height: 210px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

@media only screen and (min-width: 1248px) {
    .media {
        height: 420px;
    }
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 16px;
        height: 50px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f7f4ed;
        max-height: 100px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #cfd3e5;
        border-radius: 100vh;
        border: 3px solid #f6f7ed;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #07116e;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@layer base {
    /* Makes automatically all h tags to use applied colour initially */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-black;
    }

    p {
        @apply font-normal;
    }
}

@layer components {
    /*      CSS FOR FLOATING LABELS ON FORMS */
    .float-input-container {
        @apply relative mx-0 flex flex-col;
    }

    .float-input-container textarea {
        @apply h-[7.125rem] w-full resize-none rounded-3xl border-0 bg-white py-[1rem] px-[1.563rem] outline-0 hover:ring-[1px] hover:ring-blue-10 focus:border-0 focus:bg-blue-10 focus:outline-0 focus:ring-0;
    }

    .float-input-container input {
        @apply h-[2.75rem] w-full rounded-full bg-white py-2 px-[1.563rem] text-p;
    }

    .float-input-container input:hover {
        @apply bg-blue-10 ring-[1px] ring-blue-20;
    }

    .float-input-container label {
        @apply pointer-events-none absolute -top-[2px] py-0 px-[1.563rem] text-p text-darkgray;
        transform: translate(0, 14px) scale(1);
        transform-origin: top left;
        transition: all 0.2s ease-out;
    }

    .float-input-container:focus-within label {
        @apply rounded-md bg-white text-black;
        padding: 4px 8px;
        transform: translate(20px, -9px) scale(0.75);
    }

    .active-float-label {
        @apply rounded-md bg-white text-black;
        padding: 4px 8px !important;
        transform: translate(20px, -9px) scale(0.75) !important;
    }

    .float-input-container input:-webkit-autofill ~ label {
        /* Makes labels float on autofill */
        @apply rounded-md bg-white text-black;
        padding: 4px 8px;
        transform: translate(20px, -9px) scale(0.75);
    }

    .contact-us textarea {
        @apply bg-blue-5;
    }

    .contact-us input {
        @apply bg-blue-5;
    }

    /* END OF FLOAT CSS */
    .error-field {
        @apply ring-[1px] ring-negative-100;
    }

    .error-text {
        @apply mt-0.5 px-[25px] text-p13 text-negative-100;
    }

    .input-field {
        @apply flex h-14 w-full rounded-full bg-blue-5 px-[25px] hover:ring-[1px] hover:ring-blue-10 focus:bg-blue-10;
    }

    .digit-field {
        @apply desktop:m-1.5 tablet:m-1 m-0.5 h-[54px] w-[100%] max-w-[54px] rounded-full border-none bg-inputgray p-5 text-center text-h6 hover:ring-[1px] hover:ring-blue-10 focus:bg-blue-10;
    }

    .textarea-field {
        @apply mt-6 flex h-28 w-full resize-none rounded-3xl border-0 bg-blue-5 px-[36px] pt-[18px] outline-0 hover:ring-[1px] hover:ring-blue-10 focus:border-0 focus:bg-blue-10 focus:outline-0 focus:ring-0;
    }

    .input-label {
        @apply mb-[0.375rem] mt-7 px-[36px] text-p13 font-medium;
    }

    /* Input settings below are for css when autofilling input fields */
    /* For clas .autofill-bg-white  */
    .autofill-bg-white input,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }

    /* The rest of the auiofill fields */
    input:-webkit-autofill:not(.autofill-bg-white),
    input:-webkit-autofill:hover:not(.autofill-bg-white),
    input:-webkit-autofill:focus:not(.autofill-bg-white),
    input:-webkit-autofill:active:not(.autofill-bg-white) {
        -webkit-box-shadow: 0 0 0 30px #f3f4f8 inset !important;
    }

    input:focus {
        outline: none !important;
    }

    /* ^^^ */
    .primary-button-color-scheme {
        @apply bg-orange-100 text-white desktop:bg-orange-100 hover:desktop:bg-orange;
    }

    .secondary-button-color-scheme {
        @apply bg-darkblue text-white desktop:bg-lightblue hover:desktop:bg-darkblue;
    }

    .secondary-light-button-color-scheme {
        @apply bg-blue-20 text-darkblue desktop:bg-blue-10 hover:desktop:bg-blue-20;
    }

    .tertiary-button-color-scheme {
        @apply bg-orange-20 text-orange desktop:bg-orange-10 hover:desktop:bg-orange-20;
    }

    .disabled-button-color-scheme {
        @apply bg-gray text-white;
    }

    .find-trip-button-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 50px 24px 40px;
        gap: 16px;
        position: relative;
        background: linear-gradient(1.2deg, rgba(1, 2, 2, 0.5) 32.71%, rgba(1, 2, 2, 0) 81.92%);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .find-trip-button {
        @apply bg-orange-100 text-white w-[224px] h-[46px] rounded-3xl px-[1.5rem] py-[0.75rem] hover:bg-orange hover:cursor-pointer;
    }

    .flex-mobile {
        flex: 1 1 160px;
    }

    .usp-item-hover > p {
        text-decoration: underline;
    }

    .raq-form-shadow {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    }

    .phone-input ::placeholder {
        @apply text-darkgray
    }

    /* Fix for windows 125% default scaling */
    @media (-webkit-device-pixel-ratio: 1.25) {
        .scaling {
            zoom: 0.95;
        }
    }
}