/* ##### LIGHTBOX ##### */

.ril__outer {
  background-color: rgba(0, 0, 0, 1) !important;
}
.ril__captionContent {
  margin: auto;
}
.ril-toolbar__item.ril__toolbarItem:nth-child(3) {
  background-color: rgb(231 233 242 /100);
  border-radius: 1rem;
  width: 2.75rem;
  height: 2.75rem;
}
.ril-close.ril-toolbar__item__child.ril__toolbarItemChild.ril__builtinButton.ril__closeButton {
  background-image: url(https://res.cloudinary.com/vacationscostarica-com/image/upload/v1646821729/X_icon_13228dd7c0.svg);
  margin-top: -8px;
  margin-left: 2px;
}

/* ##### END OF LIGHTBOX ##### */
