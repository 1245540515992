/* ##### TOP NAVIGATION ##### */

.dropdown {
  position: absolute;
  display: block;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 18.75rem;
  padding: 2rem;
  list-style: none;
  background-color: #fff;
  border-radius: 1rem;
}

.dropdown-menu {
  top: 3.45rem;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-profile {
  top: 3.2rem;
  right: 0px;
}

/* ##### END OF TOP NAVIGATION ##### */
